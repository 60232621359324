import React, { useEffect, useState } from "react";
import Translations from "../../../translations";
import { ReactComponent as BuscaSVG } from "../../../assets/svg/busca.svg";
import ProfessionalsSwiper from "../../swiper";
import News from "../../news";
import Articles from "../../articles";
import Actings from "../../actings";
import Banner from "../../banners"

import i18n from '../../../translations/i18n'

import Footer from "../../footer";


import Modals from "../../modal";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const Index = ({showModal, setShowModal}) => {

  
  const [searchProfesional, setSearchProfesional] = useState("");
  const [news, setNews] = useState([])
  const [articles, setArticles] = useState([])
  const [actings, setActings] = useState([])

  const [profesionals, setProfessionals] = React.useState([]);
  const [profesional, setProfessional] = React.useState();
  const [indexProfesional, setIndexProfesional] = React.useState(0);


  useEffect(() => {
    window.addEventListener("hashchange", () => {
      if(window.location.hash == ''){
        setShowModal('')
      }
    }, false);
  }, [window.location.hash])

  return (
    <>
      <main>
    
        <section className="banner" id="home">
          <Banner />
          <a href="#quemsomos" className="mouse">
            <span></span>
          </a>
        </section>

        <a id="quemsomos"></a>
        <section className="quemsomos">
          <div className="frase">
            <h1 className="h3">
              <Translations>home.Excellence</Translations>
            </h1>
          </div>
          <div className="about-us">
            <div className="row">
              <div className="col-md-6">
              <div className="txtDesktop">
                <Translations>home.We_are_an_office</Translations> 
                
              </div>
                <div className="txtMobile">
                <Translations>home.We_are_an_office_mobile</Translations> 
                </div>
                <p><a onClick={() => {setShowModal('recognition', '#recognition')}} className="links"><Translations>modal.recognition.name</Translations></a></p>
              </div>
            </div>
          </div>
        </section>

        <section className="atuacao" id="atuacao">
          <Actings actings={actings} setActings={setActings} showModal={showModal} setShowModal={setShowModal} />
        </section>

        <section className="professional" id="profissionais">
          <ul class="profissionais-watermark">
            {[...Array(5)].map((_, repeatIndex) => (
            profesionals.map((prof, index) => (
              <li key={`${prof.id}-${repeatIndex}-${index}`}> {prof.name} </li>
            ))
          ))}
          </ul>
          <div className="formbusca">
            <h2><Translations>menu.Professionals</Translations></h2>
            <div className="form">
              {i18n.language == 'pt' ? (
                <input type="text" placeholder='digite o n&zwnj;o&zwnj;m&zwnj;e aqui' onChange={(e) => {setSearchProfesional(e.target.value)}} />
              ) : (<input type="text" placeholder='Type the name here' onChange={(e) => {setSearchProfesional(e.target.value)}} />) }
              
              <button>
                <BuscaSVG />
              </button>
            </div>
          </div>



          <div className="container swiper-professionals">
            <ProfessionalsSwiper
                showModal={showModal} 
                setShowModal={setShowModal}
                profesionals={profesionals}
                setProfessionals={setProfessionals}
                profesional={profesional}
                setProfessional={setProfessional}
                indexProfesional={indexProfesional}
                setIndexProfesional={setIndexProfesional}
                searchProfesional={searchProfesional} 
              />
          </div>

          <div className="container-fluid w-100">
            <div className="row w-100">
              <div className="col-sm-6"></div>
              <div className="col-sm-6" style={{padding:0, position: 'absolute', bottom:-10, right:0}}>
              </div>
            </div>
          </div>
        </section>

        <section className="midias" id="midias">
          <div className="col-md-6 midias-conteudo">
            <h3 className="h2"><Translations>menu.News</Translations></h3>

            <div className="news">
              <h4 className="h3 bg-primary cursor" onClick={() => {setShowModal('news', '#news-0')}}><Translations>modal.news.title</Translations></h4>
              <News news={news} setNews={setNews} showModal={showModal} setShowModal={setShowModal} />
            </div>
            <div className="acticles">
              <h4 className="h3 bg-primary cursor"  onClick={() => { setShowModal('articles', '#articles-0') }}><Translations>modal.articles.title</Translations></h4>
              <Articles articles={articles} setArticles={setArticles} showModal={showModal} setShowModal={setShowModal} />
            </div>
          </div>
        </section>

        <Footer />
      
        
        <Modals 
          news={news}
          articles={articles}
          actings={actings}
          profesionals={profesionals}
          setProfessionals={setProfessionals}
          profesional={profesional}
          setProfessional={setProfessional}
          indexProfesional={indexProfesional}
          setIndexProfesional={setIndexProfesional}
          showModal={showModal} 
          setShowModal={setShowModal} />
      </main>
    </>
  );
};

export default Index;
