import i18n from '../translations/i18n'
class Api {
    static handleURL = (path) => {
        return `http://barrospimentel.adv.br/barros-pimentel-advogados-php${path}`;
    }

    static fetch = (url, method, body) => {
        return  fetch(Api.handleURL(url), {
            headers: {
                'Content-Type': 'application/json',
            },
            method: method,
            body: JSON.stringify(body)
        })
    }

    static json = (method, url, body) => {
        return Api.fetch(url, method, body).then((response) => {
            return response.json()
        })
    }

    static GET = (url) => {
        return Api.json("GET", url)
    }

    static POST = (url, body) => {
        return Api.json("POST", url, body)
    }

    static PUT = (url, body) => {
        return Api.json("PUT", url, body)
    }

    static DELETE = (url, body) => {
        return Api.json("DELETE", url, body)
    }

    static GetLanguage(){
        console.log(i18n.language)
        if(i18n.language === "pt" || i18n.language === "en"){
            return i18n.language
        }
        return 'pt'
    }
}

export default Api